(function ($) {

    jQuery.fn.mortgageCalc = function () {

        var el = $(this);
        var _this = this;

        if (!el.length) {
            return false;
        }

        var mortgageFieldsLimits = {
            'wage': [1, 30],
            'term': [1, 30],
            'first-payment-percent': [0, 75]
        };

        this.mortgageCalculator = function (months, creditSum, wage) {

            wage = parseFloat(wage);
            months = parseInt(months);

            var month_rate = wage / 100 / 12;
            var k = (month_rate * Math.pow((1 + month_rate), months)) / (Math.pow((1 + month_rate), months) - 1);
            var payment = k * creditSum;

            return parseFloat(payment) || 0;
        };

        this.formatString = function (integer, round) {
            return parseFloat(integer).toFixed(parseInt(round)).replace(/./g, function (c, i, a) {
                return i && c !== "." && ((a.length - i) % 3 === 0) ? ' ' + c : c;
            })
        };

        this.getEl = function (field) {
            return $("[data-mortgage='" + field + "']", el);
        };

        this.calcMortgage = function (send_counter_event) {
            var send_event = send_counter_event || false;
            var years = _this.getEl('term').val().replace(',', '.') || 0;
            var months = Number((parseFloat(years) * 12).toFixed(1));
            var wage = parseFloat(_this.getEl('wage').val().replace(/[^\d.]+/g, '')) || 0;
            var firstPayment = parseInt(_this.getEl('first-payment-sum').val().replace(/[^\d]+/g, '')) || 0;
            var totalCreditSum = parseInt(_this.getEl('total').val().replace(/[^\d]+/g, '')) || 0;
            var creditSum = totalCreditSum - firstPayment;
            _this.getEl('credit-sum').text(_this.formatString(creditSum));

            var monthPayment = _this.mortgageCalculator(months, creditSum, wage);
            _this.getEl('result').text(_this.formatString(monthPayment));

            var creditTotalCost = monthPayment * months;
            _this.getEl('credit-total-cost').text(_this.formatString(creditTotalCost));

            var creditCost = creditTotalCost - creditSum;
            _this.getEl('credit-cost').text(_this.formatString(creditCost));

            if (send_event) {
                $('body').trigger('mortgagecalc');
            }

            return false;
        };

        this.limits = function (value, min, max) {
            if (value > max) {
                value = max;
            }
            if (value < min) {
                value = min;
            }

            return value;
        };


        this.checkLimits = function (el, value) {
            var elId = el.attr('id');
            if (mortgageFieldsLimits[elId]) {
                value = this.limits(value, mortgageFieldsLimits[elId][0], mortgageFieldsLimits[elId][1]);
            }
            return value;
        };

        this.processFirstPayment = function (el) {
            var elId = el.data('mortgage');

            var firstPaymentSum = parseInt(_this.getEl('first-payment-sum').val().replace(/[^\d]+/g, '')) || 0;
            var firstPaymentPercent = parseInt(_this.getEl('first-payment-percent').val().replace(/[^\d]+/g, '')) || 0;
            var totalCreditSum = parseInt(_this.getEl('total').val().replace(/[^\d]+/g, '')) || 0;

            // Если сейчас изменяют поле "сумма первоначального взноса", то на его основе корректируем процент
            if (elId === 'first-payment-sum') {
                firstPaymentPercent = parseInt(firstPaymentSum / totalCreditSum * 100) || 0;
                _this.getEl('first-payment-percent').val(firstPaymentPercent);
            }
            // В противном случае меняем сумму первоначального взноса на основании указанного процента
            else {
                firstPaymentSum = parseInt(totalCreditSum / 100 * firstPaymentPercent) || 0;
                _this.getEl('first-payment-sum').val(_this.formatString(firstPaymentSum));
            }

        };

        el
            .on('change', 'input', function () {
                _this.calcMortgage(true);
                _this.processFirstPayment($(this));
            })
            .on('keyup', 'input', function (e) {
                var isFloat = $(this).data('format') === 'float';
                _this.processFirstPayment($(this));
                var value = $(this).val();
                if (isFloat) {
                    value = value ? value.replace(/[^\d.,]+/g, '') : 0;
                    value = value ? value.replace(',', '.') : 0;
                } else {
                    value = value ? value.replace(/[^\d]+/g, '') : 0;
                }

                value = _this.checkLimits($(this), value);

                if (isFloat && value && value.indexOf('.') !== -1) {

                } else {
                    value = _this.formatString(value);
                }

                value = value ? value : '';

                $(this).val(value);

                return true;
            });


        _this.getEl('calc').on('click', function () {
            _this.calcMortgage(true);
            return false;
        });

        this.calcMortgage();

    };


})(jQuery);

