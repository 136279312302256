$(function () {
    var anoticePopup = $('#anotice-popup');
    var popperInstance;

    $('body')
        .on('click', '.anotice-menu', function (event) {
            event.preventDefault();
            event.stopPropagation();

            var advertiserInfo = $(this).data('info');
            var disclaimer = $(this).data('disclaimer').trim();
            var erid = $(this).data('erid');

            $('#anotice-erid').data('copy', erid);
            $('#anotice-erid-value').text('Erid: ' + erid);
            $('#anotice-popup-content').html(advertiserInfo);
            var disclaimerContainer = $('#anotice-disclaimer');

            disclaimerContainer.html(disclaimer);

            if (disclaimer.length > 0) {
                disclaimerContainer.addClass('visible');
            } else {
                disclaimerContainer.removeClass('visible');
            }

            anoticePopup.show();

            popperInstance = Popper.createPopper($(this)[0], anoticePopup[0], {
                placement: 'bottom-end'
            });

            return false;
        });

    $('#anotice-close').on('click', function (event) {
        event.stopPropagation();
        event.preventDefault();
        event.stopPropagation();

        if (popperInstance) {
            anoticePopup.hide();
            popperInstance.destroy();
        }
        return false;
    });


    $('#anotice-erid').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        var text = $(this).data('copy');
        var link = $(this);
        if (text) {
            copyToClipboard(text).then(function () {
                var label = link.find('[data-copy-label]');
                link.addClass('success');
                var oldLabel = label.text();
                label.text(label.data('copy-success'));
                setTimeout(function () {
                    label.text(oldLabel);
                    link.removeClass('success');
                }, 3000);
            });
        }
    });

    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            var textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise(function (res, rej) {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }
});