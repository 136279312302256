(function($){
	jQuery.fn.isVisible = function(options){

		if(!$(this).length){
			return false;
		}

		var top_of_element = $(this).offset().top;
		var bottom_of_element = $(this).offset().top + $(this).outerHeight();
		var bottom_of_screen = $(window).scrollTop() + $(window).height();
		var top_of_screen = $(window).scrollTop();

		return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element);
	};
})(jQuery);