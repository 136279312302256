function modalbox(params) {

    var defaults = {
        type: 'default',
        cssBaseClass: 'landing-modal',
        cssWindowClass: 'landing-modal-window',
        cssCloseClass: 'landing-modal-close',
        cssBackgroundClass: 'landing-modal-background',
        closeContent: ''
    };

    var options = $.extend({}, defaults, params || {});
    options.cssBackgroundClass += ' ' + options.cssBaseClass + '-' + options.type;

    var modal = this;

    modal.background = $('<div>', {class: options.cssBackgroundClass});
    modal.window = $('<div>', {class: options.cssWindowClass});
    modal.closeButton = $('<a>', {class: options.cssCloseClass}).attr('href', '#').html(options.closeContent);

    modal.onClose = options.onClose || function () {
    };

    modal.open = function () {

        $('body')
            .append(modal.background)
            .append(modal.closeButton)
            .append(modal.window);

        modal.closeButton.on('click', function () {
            modal.onClose(modal);
            modal.close();
            return false;
        });

        modal.background.on('click', function (event) {
            modal.onClose(modal);
            modal.close();
            return false;
        });

        $(document).keyup(function (e) {
            if (parseInt(e.keyCode ? e.keyCode : e.charCode) === 27) { // escape key maps to keycode `27`
                modal.onClose(modal);
                modal.close();
                return false;
            }
        });
    };

    modal.close = function () {
        modal.closeButton.off('click');
        modal.background.off('click');
        modal.background.remove();
        modal.window.remove();
        modal.closeButton.remove();
    };

    modal.content = function (data) {
        modal.window.html(data);
    };
    this.window.on('click', function (e) {
        e.stopPropagation();
    });

    return modal;
}