function initAdaptiveSlider(selector, maxColumns, params) {

    maxColumns = maxColumns || 1;

    var breakpoints = {
        1: {}, 2: {
            320: {
                slidesPerView: 1, slidesPerGroup: 1,
            }, 770: {
                slidesPerView: 2, slidesPerGroup: 2,
            },
        }, 3: {
            320: {
                slidesPerView: 1, slidesPerGroup: 1,
            }, 770: {
                slidesPerView: 2, slidesPerGroup: 2,
            }, 1024: {
                slidesPerView: 3, slidesPerGroup: 3,
            },
        }, 4: {
            320: {
                slidesPerView: 1, slidesPerGroup: 1,
            }, 770: {
                slidesPerView: 2, slidesPerGroup: 2,
            }, 1024: {
                slidesPerView: 3, slidesPerGroup: 3,
            }, 1260: {
                slidesPerView: 4, slidesPerGroup: 4,
            }
        }
    };

    var config = {
        breakpoints: breakpoints[maxColumns]
    };

    if (params) {
        $.each(params, function (key, value) {
            config[key] = value;
        });
    }

    var hash = location.hash.substr(1);
    if (hash.lastIndexOf('place_id_', 0) > 0) {
        config.autoplay = null;
    }

    $(selector).gallerySlider(config);
}
