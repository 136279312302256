$(function () {

    // Вызываем счетчик показа ФБ
    banner_counter();

    $('#menu-toggle').click(function () {
        $($(this).data('toggle')).toggle();
        return false;
    });

    $('form').submit(function (e) {
        $(this).find('input').filter(function () {
            return !$.trim(this.value).length;  // get all empty fields
        }).prop('disabled', true);
    });

    var interval = null;
    $('#search-form').on('change', function () {
        $('#search-form-found').text('подождите');
        var form = $(this);
        clearTimeout(interval);
        interval = setTimeout(function () {
            var data = form.serialize();
            $.post(form.attr('action'),
                data + '&count=1',
                function (response) {
                    if (response.count) {
                        $('#search-form-found').html(response.count);
                    }
                }, 'json');
        }, 1000);
    });

    $('.search-form-expander').on('click', function () {
        $(this).find('.fa').toggleClass('fa-chevron-up fa-chevron-down');
        $('.search-form-expandable').toggleClass('search-form-expand');
        alignStickyForm();
    });

    $('.toggle-click').on('click', function () {
        var _this = $(this);
        var target = $(this).data('target');

        if ($(target).data('overlay') == 1) {
            if ($(target).css('display') === 'none') {
                if (!$('#toggle-overlay').length) {
                    var overlay = $('<div>', {'class': 'overlay', 'id': 'toggle-overlay'});
                    overlay.on('click', function () {
                        _this.trigger('click');
                        return false;
                    });
                    $('body').append(overlay);
                }
            } else {
                if ($('#toggle-overlay').length) {
                    $('#toggle-overlay').remove();
                }
            }
        }
        $(target).toggle();
        return false;
    });

    // Обычный показ внизу страницы
    initAdaptiveSlider('.photoblock-slider', 4,  {pager: true});

    // Промо-блок на всю ширину страницы
    var coverSizes = {
        'xl': 1360,
        'lg': 1020,
        'md': 720,
        'sm': 420
    };
    var windowWidth = $(window).width();
    var coverSizeAddon = '';
    for (var sizeName in coverSizes) {
        if (windowWidth <= coverSizes[sizeName]) {
            coverSizeAddon = '-' + sizeName; // Включить когда будут загружены все картинки с полным набором картинок
        }
    }

    setTimeout(function(){
        $('.lazy-cover').each(function () {
            var bgimage = $(this).data('cover' + coverSizeAddon) || $(this).data('cover');
            if (bgimage) {
                $(this).css('background-image', 'url("' + bgimage + '")');
            }
        });
    }, 2000);


    initAdaptiveSlider('.promo-pb-slider', 1, {slideSpace: 0, fade: true});
    initAdaptiveSlider('.photoblock-search-slider', 2);

    $('#mortgage').mortgageCalc();

    $('[data-lightbox]').lightbox();
    $('[rel^="lightbox"]').lightbox();

    $('body').on('click', '[data-phone]', function () {
        var phone = $(this).data('phone');
        if (phone) {
            $(this).data('phone', null);
            $(this).html(phone);
            return false;
        }
        return true;
    });

    $('[data-toggle-disabled]').on('change', function () {
        var target = $(this).data('toggle-disabled');
        var button = $(target);
        var checked = $(this).prop('checked');
        button.prop('disabled', !checked);
    });
});