jQuery.fn.gallerySlider = function (params) {

    return this.each(function () {

        params = params || {};

        var element = $(this);
        var domElement = element.get(0);

        var parameters = {
            loop: true,
            speed: 300,
            slidesPerView: 1,
            slidesPerGroup: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }

        if (typeof params.breakpoints !== 'undefined') {
            parameters.breakpoints = params.breakpoints;
        }

        if (typeof params.slidesPerView !== 'undefined') {
            parameters.slidesPerView = params.slidesPerView;
            parameters.slidesPerGroup = params.slidesPerView;
        }

        if (typeof params.autoplay === 'undefined' || params.autoplay === true) {
            parameters.autoplay = {
                delay: 5000,
                pauseOnMouseEnter: true,
            };
        } else if (typeof params.autoplay === 'object') {
            parameters.autoplay = params.autoplay;
        }

        if (typeof params.fade !== 'undefined') {
            parameters.speed = 2000;
            parameters.effect = 'fade';
            parameters.fadeEffect = {
                crossFade: true
            };
        }

        if (typeof params.pager !== 'undefined') {
            parameters.pagination = {
                el: '.swiper-pagination',
                //type: 'bullets',
                dynamicBullets: true,
                clickable: true,
            };
        }

        new Swiper(domElement, parameters);
    });
};
