$.fn.lightbox = function () {

    var elements = {};

    var $this = this;

    this.each(function () {
        var album = $(this).data('lightbox');

        if (album) {
            elements[album] = $('[data-lightbox="' + album + '"]');
        } else {
            elements['default'] = $($this);
            return false;
        }
    });

    $.each(elements, function (name, element) {
        init(element);
    });

    function init(e) {

        var openedModalbox = false;
        var lightbox;
        var items = $(e);
        var thumbs = [];

        var total = items.length;
        var index = 0;

        var arrowLeft = $('<span>', {class: 'lightbox-arrow lightbox-arrow-left'});
        var arrowRight = $('<span>', {class: 'lightbox-arrow lightbox-arrow-right'});
        var thumbCont = $('<div>', {class: "lightbox-thumbs"});

        e.on('click', function () {

            if (openedModalbox) {
                return false;
            }

            lightbox = new modalbox({
                onClose: function () {
                    close();
                }
            });
            lightbox.open();
            index = $(this).index();
            //initThumbs();
            initArrows();
            loadImage($(this));
            return false;
        });

        var initArrows = function () {
            if (total > 1) {
                arrowLeft.on('click', function () {
                    prevImage();
                    return false;
                });

                arrowRight.on('click', function () {
                    nextImage();
                    return false;
                });

                $('body')
                    .append(arrowLeft)
                    .append(arrowRight);

                $(document).on('keydown.lightbox', function (e) {
                    var keycode = parseInt(e.keyCode || e.which);

                    if (keycode === 39) {
                        nextImage();
                    }

                    if (keycode === 37) {
                        prevImage();
                    }
                });
            }
        };

        var initThumbs = function () {
            if (!thumbs.length) {
                items.each(function (key, item) {
                    var imageSrc = $(item).attr('href');
                    var thumb = $('<span>', {class: 'lightbox-thumb'});
                    thumb.css('background-image', 'url("' + imageSrc + '")');
                    thumbs.push(thumb);
                });
            }
            if (thumbs.length < 2) {
                return;
            }
            thumbCont.html('').append(thumbs);
            $('body').append(thumbCont);

            thumbCont.on('click.lightbox', '.lightbox-thumb', function () {
                index = $(this).index();
                loadImage(items.eq(index));
            })
        };

        var activateThumb = function () {
            $.each(thumbs, function (key, thumb) {
                thumb.removeClass('active');
            });
            if (thumbs[index]) {
                thumbs[index].addClass('active');
                thumbCont.scrollTo(thumbs[index], 200);
            }
        };

        var close = function () {
            arrowLeft.remove();
            arrowRight.remove();
            $('.lightbox-thumbs').remove();
            $(document).off('keydown.lightbox');
        };

        var loadImage = function (item) {

            var src = item.attr('href');
            var title = item.attr('title');

            var yt_code = item.data('youtube');

            var cont = '';
            if (yt_code) {
                var windowWidth = $(window).width();
                var windowHeight = $(window).height();
                var width = parseInt(windowWidth * 0.8);
                var height = parseInt(windowHeight * 0.8);

                cont = $('<iframe>');
                cont.attr('src', 'https://www.youtube.com/embed/' + yt_code + '?controls=1&fs=1&rel=0&showinfo=1');
                cont.attr('width', width);
                cont.attr('height', height);
                cont.attr('frameborder', 0);

                lightbox.content(cont);
            } else {
               // console.log(src);
                var image = $('<img>', {class: 'lightbox-image', 'src': src});
                cont = $('<div>', {class: 'lightbox-image-cont'}).append(image);

                image.on('load', function () {
                    lightbox.content(cont);
                });

                image.on('click', function (e) {
                    e.stopPropagation();
                    nextImage();
                    return false;
                });
            }

            if (title) {
                var desc = $('<div>', {class: 'lightbox-image-desc'}).html(title);
                cont.append(desc);
            }

            //activateThumb();
        };

        var nextImage = function () {
            index++;
            if (index > total - 1) {
                index = 0;
            }

            var item = items.eq(index);
            loadImage(item);
        };

        var prevImage = function () {
            index--;
            if (index < 0) {
                index = total - 1;
            }

            var item = items.eq(index);
            loadImage(item);
        };
    }

};