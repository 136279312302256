function decode_mail(el) {
    el.innerHTML = getmailto(el);
    return true;
}

function getmailto(el) {
    var mailto = el.getAttribute("data-rel") ? el.getAttribute("data-rel") : el.rel;
    mailto += '@';
    mailto += el.getAttribute("data-to") ? el.getAttribute("data-to") : el.id + '.';
    mailto += el.getAttribute("data-name") ? el.getAttribute("data-name") : el.name;
    if ($(el).attr('data-subject')) {
        mailto += '?subject=' + $(el).attr('data-subject');
    }
    el.href = "mailto:" + mailto;

    return mailto;
}